export enum AnalyticsEventNames {
  // Global
  SEGMENT_ANALYTICS_INITIALIZED = 'Segment Analytics Initialized',
  ADMIN_VOTE_ON_BETA_FEATURE = 'Admin Vote On Beta Feature',
  BUILD_OFFER_VIEW = 'Build Offer View',
  DEAL_EXPORT_OFFER = 'Export Offer',
  DEAL_EXPORT_PAST_OFFER = 'Export Past Offer',
  DEAL_INITIATE_ACTION = 'Initiate Action On Offer',
  DEAL_CANCEL_ACTION = 'Cancel Action On Offer',
  DEAL_CONFIRM_ACTION = 'Confirm Action On Offer',
  DEAL_VIEW_OFFER_DETAILS = 'View Offer Details',
  DEAL_VIEW_BUYER_DETAILS = 'View Buyer Profile',
  DEAL_VIEW_SELLER_DETAILS = 'View Seller Profile',
  DEAL_VIEW_LISTING_DETAILS = 'View Listing',
  DEAL_VIEW_PAST_OFFER_DETAILS = 'View Past Offer Details',
  // Listings
  ADMIN_SHARE_LISTING = 'Admin Share Listing',
  // Seller
  ADMIN_ACTIVATES_SELLER = 'Admin Activates Seller',
  ADMIN_REACTIVATES_SELLER = 'Admin Reactivates Seller',
  // Payments
  PAYMENT_PLAN_INITIATED = 'Payment Plan Initiated',
  PAYMENT_PLAN_INITIATION_FAILED = 'Payment Plan Initiation Failed',
  PAYMENT_PLAN_COMPLETED = 'Payment Plan Completed',
  PAYMENT_PLAN_COMPLETION_FAILED = 'Payment Plan Completion Failed',
  PAYMENT_PLAN_UPDATED = 'Payment Plan Updated',
  PAYMENT_PLAN_UPDATE_FAILED = 'Payment Plan Update Failed',
  BUYER_PAYMENT_REQUEST_INITIATED = 'Buyer Payment Request Initiated',
  BUYER_PAYMENT_REQUEST_CONFIRMED = 'Buyer Payment Request Confirmed',
  BUYER_PAYMENT_REQUEST_CANCELLED = 'Buyer Payment Request Cancelled',
  BUYER_PAYMENT_REQUEST_FAILED = 'Buyer Payment Request Failed',
  PAYMENT_DUE_DATE_UPDATED = 'Payment Due Date Updated',
  PAYMENT_DUE_DATE_UPDATE_FAILED = 'Payment Due Date Update Failed',
  BUYER_PAYMENT_SETTINGS_UPDATED = 'Buyer Payment Settings Updated',
  BUYER_PAYMENT_SETTINGS_UPDATE_FAILED = 'Buyer Payment Settings Update Failed',
  // Inventory
  PRODUCT_MATCHED = 'Product Matched',
  NO_PRODUCT_MATCHES_FOUND = 'No Product Matches Found',
  // Sharing
  ADMIN_VOTES_ON_ML_RECOMMENDATION = 'Admin Voted on ML Recommendation',
}

/** Used as additional property values for the events: DEAL_INITIATE_ACTION, DEAL_CANCEL_ACTION, and DEAL_CONFIRM_ACTION. */
export enum DealAnalyticsEventActions {
  ACCEPT_OFFER = 'Accept Offer',
  DECLINE_OFFER = 'Decline Offer',
  LOG_SELLER_ACCEPT = 'Log Seller Accept',
  LOG_SELLER_COUNTER = 'Log Seller Counter',
  LOG_BUYER_ACCEPT = 'Log Buyer Accept',
  SEND_TO_BUYER = 'Send To Buyer',
  SEND_TO_BP = 'Send To BP',
  SEND_TO_AM = 'Send To Am',
  SEND_TO_SELLER = 'Send To Seller',
  WITHDRAW_OFFER = 'Withdraw Offer',
  REACTIVATE_DEAL = 'Reactivate Deal',
  DELETE_DEAL_CYCLE = 'Delete Deal Cycle',
}

export enum BuildOfferAnalyticsEventActions {
  DELETE_ONE_PRODUCT = 'Delete One Product',
  UNDO_PRODUCT_DELETION = 'Undo Product Deletion',
}
